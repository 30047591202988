import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
  IconButton,
  Modal,
} from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CloseIcon from '@mui/icons-material/Close';
import qrCodeProfound from './qrcode-profoundwords.png';
import './App.css';
import GoogleAd from './GoogleAd';

import logo from './logo512.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {

  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [currentQrCode, setCurrentQrCode] = useState('');
  const [currentQrCodeTitle, setCurrentQrCodeTitle] = useState('');
  const [currentQrCodeDescription, setCurrentQrCodeDescription] = useState('');
  const [currentQrCodeLink, setCurrentQrCodeLink] = useState('');

  const handleQrCodeOpen = (qrCodeImage) => {
    setCurrentQrCode(qrCodeImage);
    setQrCodeOpen(true);
  };
  
  const handleQrCodeClose = () => {
    setQrCodeOpen(false);
    setCurrentQrCode('');
    setCurrentQrCodeTitle('');
    setCurrentQrCodeDescription('');
    setCurrentQrCodeLink('');
  };

  const blocks = [
    { title: 'President Trump\'s Phrases', description: 'History is written by winners', image: 'images/trump.png?v=1', link: 'https://trump.profoundwords.us/', qrcode: 'images/qr_trump.png' },
    { title: 'Situation Idioms', description: 'Chinese Idioms in real-life political scenarios', image: 'images/situation-idioms.png?v=1', link: 'https://situation.profoundwords.us/', qrcode: 'images/qr_situation.png'  },
    // { title: 'Russell M. Nelson', description: 'Inspiring Stories', image: 'images/prophet17.jpg', link: 'https://prophet17.profoundwords.us/' },
    { title: 'Political Analysis Phrases', description: 'US Political Analysis Phrases', image: 'images/political-analysis.png', link: 'https://pap.profoundwords.us/', qrcode: 'images/qr_pap.png'  },
  ];

  const secondBlocks = [
    // { title: 'Ads Zone', description: 'Advertisements', image: '', link: 'https://ads.profoundwords.us/' },
    { title: 'Mint Mobile', description: 'Referral Link', image: 'images/mint-mobile.jpg', link: 'http://fbuy.me/uy2-s' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="app-container">
        <AppBar position="static">
          <Toolbar>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Box display="flex" alignItems="center">
                <img src={logo} alt="Logo" style={{ height: 80, marginRight: 16 }} />
                <Typography variant="h5" component="div" className="app-title">
                  <i>Data Value-Added AI Service Platform</i>
                </Typography>
              </Box>
              <IconButton color="inherit" onClick={() => {
                  setCurrentQrCodeTitle('Profound Words');
                  setCurrentQrCodeDescription('Data Value-Added AI Service Platform');
                  setCurrentQrCodeLink('https://profoundwords.us/');
                  handleQrCodeOpen(qrCodeProfound);
                }} aria-label="QR Code">
                <QrCodeIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" className="main-content">
          <Typography variant="h6" component="h1" gutterBottom className="intro-heading">
            <i>Recap YouTube Videos with AI Insights: Extracting, Generating, and Inspiring!</i>
          </Typography>

          <Typography variant="subtitle1" gutterBottom className="intro-subtitle">
            <b><u>Theme Websites</u></b>
          </Typography>
          <br />
          <Grid container spacing={2}>
            {blocks.map((block, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  onClick={() => {
                    if (block.link) {
                      window.open(block.link, '_blank', 'noopener,noreferrer');
                    }
                  }}
                  className={`card-container ${block.link ? 'clickable' : ''}`}
                >
                  <Card className="card">
                    <CardMedia
                      component="img"
                      image={block.image || 'https://via.placeholder.com/140'}
                      alt={block.title}
                    />
                    <CardContent className="card-content">
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography gutterBottom variant="h6" component="h5" className="card-title">
                          {block.title}
                        </Typography>
                        {block.qrcode && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentQrCode(block.qrcode);
                              setCurrentQrCodeTitle(block.title);
                              setCurrentQrCodeDescription(block.description);
                              setCurrentQrCodeLink(block.link);
                              handleQrCodeOpen(block.qrcode);
                            }}
                          >
                            <QrCodeIcon />
                          </IconButton>
                        )}
                      </Box>
                      <Typography className="card-description">
                        {block.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
          <hr></hr>
          <Typography variant="subtitle1" gutterBottom className="intro-subtitle">
            <u>Advertisements</u>
          </Typography>
          <Grid container spacing={2}>
            {secondBlocks.map((block, index) => (
              <Grid item xs={6} sm={3} md={2} key={index}>
                <Box
                  onClick={() => {
                    if (block.link) {
                      window.open(block.link, '_blank', 'noopener,noreferrer');
                    }
                  }}
                  className={`card-container ${block.link ? 'clickable' : ''}`}
                >
                  <Card className="card">
                    <CardMedia
                      component="img"
                      image={block.image || 'https://via.placeholder.com/140'}
                      alt={block.title}
                    />
                    <CardContent className="card-content">
                      <Typography gutterBottom variant="h6" component="h5" className="card-title">
                        {block.title}
                      </Typography>
                      <Typography className="card-description">
                        {block.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
              <Grid item xs={12} sm={6} md={3} key={blocks.length}>
                <Box>
                  <Card className="card">
                    <GoogleAd />
                  </Card>                  
                </Box>                
              </Grid>
          </Grid>
        </Container>
        <Box component="footer" className="footer">
          <Container maxWidth="lg">
            <Typography variant="body2" color="text.secondary" align="center">
              Copyright © 2024 Profound Words - All Rights Reserved.
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" className="contact-email">
              <Button
                startIcon={<EmailIcon />}
                href="mailto:rebecca25.sun@gmail.com"
                color="inherit"
                size="small"
              >
                Contact Email
              </Button>
            </Typography>
          </Container>
        </Box>
      </Box>
      <Modal
        open={qrCodeOpen}
        onClose={handleQrCodeClose}
        aria-labelledby="qr-code-modal"
        aria-describedby="qr-code-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <IconButton
            onClick={handleQrCodeClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
            {currentQrCodeTitle}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}><i>{currentQrCodeDescription}</i></Typography>
          
          <img src={currentQrCode} alt="QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
          
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" href={currentQrCodeLink} target="_blank" size="small">
              Go to the website
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default App;